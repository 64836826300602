<template>
  <div id="offers">
    <b-navbar class="other" variant="faded" type="light" toggleable="lg" fixed="top">
      <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
      <b-navbar-brand :to="'/'">
        <img :src="Group49" alt="Mongolia" class="mr-4">
        <span class="text-brand">OFFERS</span>
      </b-navbar-brand>
    </b-navbar>

    <div class="contentig">
      <div class="ig" ref="ig">
        <ImagesBoard
          :grid="true"
          :rows="4"
          :columns="2"
          @calculatesize="onImagesBoardCalculateSize"
        />
      </div>
    </div>

    <div class="box-right">
      <div class="content">
        <p class="title" data-aos="zoom-out">
          Top offers in
          <br>Mongolia
        </p>
        <div class="content-1">
          <div class="img" data-aos="zoom-out"></div>
          <div class="description">
            <p
              data-aos="zoom-out"
            >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            <p
              data-aos="zoom-out"
            >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>
            <router-link to="/" class="btn btn-content-1">View Offer</router-link>
          </div>
        </div>

        <p class="text-center text-featured" data-aos="zoom-out">#FeaturedCampaigns</p>
        <div class="colum1" data-aos="zoom-out"></div>
        <div class="colum2">
          <p class="headtext" data-aos="zoom-out">
            Lorem ipsum dol
            <br>sit amet conset
          </p>
          <p
            class="textset"
            data-aos="zoom-out"
          >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>

          <div class="img2" data-aos="zoom-out"></div>
          <p class="headtext" data-aos="zoom-out">
            Lorem ipsum dol
            <br>sit amet conset
          </p>
          <p
            class="textset"
            data-aos="zoom-out"
          >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>
          <p class="text-center text-featured" data-aos="zoom-out"></p>
        </div>
      </div>

    </div>
    <NavrightHome/>
  </div>
</template>

<script>
import("aos").then(AOS => AOS.init());
import ContentsBoard from "@/components/ContentsBoard";
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";
import Group49 from "@/assets/img/offers/Group 49.svg";
export default {
  name: "offers",
  components: {
    NavrightHome,
    ImagesBoard,
    ContentsBoard
  },
  methods: {
    onImagesBoardCalculateSize(size) {
      this.$refs.ig.style.width = size.width + "px";
    }
  },
  data() {
    return {
      Group49
    };
  }
};
</script>

<style lang="scss" scoped>
#offers {
  .other {
    position: fixed;
    align-items: center;
    width: 24.75%;
    background-color: white;
    height: 15.5vh;
    .text-brand {
      margin-top: 20px;
      color: red;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .mc {
    margin: auto;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      .nav-link {
        color: #e13036;
        padding-top: 0px;
        &:hover {
          color: red;
        }

        &.router-link-exact-active {
          span {
            color: #e13036;
            padding-bottom: 5px;
            border-bottom: 3px solid #e13036;
          }
        }
      }
    }
  }

  .contentig {
    .ig {
      z-index: 10;
      position: fixed;
      width: 27%;
      height: calc(100vh - 102px);
      box-sizing: content-box;
      padding-top: 102px;
      background-color: #999;
      overflow: hidden;
    }
  }

  .box-right {
    padding-left: 24.47%;
    padding-bottom: 100%;
    background-color: #401616;
    background-image: url("../assets/img/background-offers.png");
    background-repeat: no-repeat;
    background-size: 34.01%;
    background-position: 100%;
    min-height: 100vh;
    width: 100%;

    .content {
      padding: 130px 50px 0;

      .title {
        font-size: 50px;
        font-weight: bold;
        color: #e13036;
        line-height: 50px;
      }

      .content-1 {
        display: flex;

        .img {
          width: 60%;
          background-color: #b2b2b2;
        }

        .description {
          padding: 0 30px;
          font-size: 14px;
          width: calc(40% - 40px);
          color: #ffffff;

          .btn-content-1 {
            border: 1px #fff solid;
            border-radius: 0px;
            color: #fff;
            font-size: 15px;
          }
        }
      }

      .text-featured {
        margin: 50px 0;
        font-size: 60px;
        color: #401616;
        width: 90%;
        text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      }
      .colum1 {
        width: 45%;
        background-color: #b2b2b2;
        padding: 50px;
        padding-right: 10px;
        float: left;
        height: 350px;
      }
      .colum2 {
        float: right;
        width: 55%;
      }
      .headtext {
        font-size: 35px;
        font-weight: bold;
        color: #e13036;
        line-height: 50px;
        margin-left: 50px;
      }

      .textset {
        margin-left: 50px;
        font-size: 14px;
        color: #ffffff;
        margin-right: 10%;
        margin-top: 30px;
      }
      .img2 {
        width: 80%;
        background-color: #b2b2b2;
        float: right;
        height: 300px;
        margin-right: 10%;
        margin-top: 15%;
      }
    }

  }
  
}
@media only screen and (min-width: 1920px - 1360px) {
  .other {
    width: 27.3%;
  }
  .contentig {
    width: 20%;
  }
}
</style>


